import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

interface PublicRouteProps {
    element: React.ReactElement;
    redirectTo?: string;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element, redirectTo = "/" }) => {
    const { user, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

    return !user ? element : <Navigate to={redirectTo} />;
};

export default PublicRoute;
