import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
    throw new Error('REACT_APP_API_URL is not defined in .env file');
}

const login = async (email: string, password: string) => {
    try {
        const response = await axios.post(`${API_URL}/users/login`, { email, password });
        localStorage.setItem('token', response.data.token);
        return response.data;
    } catch (error: any) {
        console.error('Login error:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.message : 'Login failed');
    }
};

const register = async (email: string, password: string, name: string, lastName: string) => {
    try {
        const response = await axios.post(`${API_URL}/users/register`, { email, password, name, lastName });
        localStorage.setItem('token', response.data.token);
        return response.data;
    } catch (error: any) {
        console.error('Registration error:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.message : 'Registration failed');
    }
};

const getUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('No token found');
    try {
        const response = await axios.get(`${API_URL}/users/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        console.error('Fetching user details error:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.message : 'Failed to fetch user details');
    }
};

const authService = {
    login,
    register,
    getUserDetails,
};

export default authService;
