// src/App.tsx
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import ProtectedRoute from './components/routes/ProtectedRoute';
import PublicRoute from './components/routes/PublicRoute';

import './index.css';
import './styles/palette.css';

const LoginPage = React.lazy(() => import('./components/auth/LoginPage'));
const RegisterPage = React.lazy(() => import('./components/auth/RegisterPage'));
const AuthorLoginPage = React.lazy(() => import('./components/auth/AuthorLoginPage'));
const AdminLoginPage = React.lazy(() => import('./components/auth/AdminLoginPage'));
const AdminDashboard = React.lazy(() => import('./components/admin/AdminDashboard'));
const AuthorDashboard = React.lazy(() => import('./components/author/AuthorDashboard'));
const MainPage = React.lazy(() => import('./components/MainPage'));
const StealthComponent = React.lazy(() => import('./components/Stealth'));
const ChatPage = React.lazy(() => import('./components/chat/ChatPage'));

const App: React.FC = () => {
    const { user, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/login" element={<PublicRoute element={<LoginPage />} redirectTo="/" />} />
                    <Route path="/register" element={<PublicRoute element={<RegisterPage />} redirectTo="/" />} />
                    <Route path="/author/login" element={<PublicRoute element={<AuthorLoginPage />} redirectTo="/author" />} />
                    <Route path="/admin/login" element={<PublicRoute element={<AdminLoginPage />} redirectTo="/admin" />} />
                    <Route path="/admin/*" element={user?.userType === 'admin' ? <AdminDashboard /> : <Navigate to="/" />} />
                    <Route path="/author/*" element={user?.userType === 'author' ? <AuthorDashboard /> : <Navigate to="/" />} />
                    <Route path="/" element={user ? <MainPage user={user} /> : <StealthComponent />} />
                    <Route path="/chat/:bookId" element={<ProtectedRoute element={<ChatPage />} />} />
                    <Route path="*" element={user ? <Navigate to="/" /> : <StealthComponent />} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default App;
